import React, { useEffect, useState } from "react";

const Createaiprompt = ({ isOpen, onClose, loadPromptsList, promptInfo }) => {
  const [formData, setFormData] = useState({});
  const [apiResponse, setApiResponse] = useState("");
  const [buttonClicked, setButtonClicked] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (promptInfo != null) {
      console.log(promptInfo);
      setFormData((prevData) => ({
        ...prevData,
        prompt_type: promptInfo.prompt_type,
        prompt_name: promptInfo.prompt_name,
        prompt_text: promptInfo.prompt_text,
        update_field: promptInfo.update_field,
      }));
    }
  }, [promptInfo]);

  const createPromptAPI = (e) => {
    e.preventDefault();
    try {
      // Disable the button
      setButtonClicked(true);
      let data = {
        prompt_type: formData.prompt_type,
        prompt_name: formData.prompt_name,
        prompt_text: formData.prompt_text,
        update_field: formData.update_field,
      };

      var method = promptInfo == null ? "POST" : "PUT";
      var addLink = promptInfo == null ? "" : "/" + promptInfo.id;

      fetch(`${process.env.REACT_APP_BASE_URL}api/ai-prompt-add` + addLink, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            onClose();
            loadPromptsList();
            // close popup and refresh the list
          } else {
            setApiResponse(data.error);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          // Handle errors
          setApiResponse(error.messag);
        });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      // Enable the button after 3 seconds
      setTimeout(() => {
        setButtonClicked(false);
      }, 2000);
    }
  };

  const listTypes = [
    { Text: "General Prompt Agent", Value: "-3" },
    { Text: "General Prompt Owner", Value: "-2" },
    { Text: "General Prompt Duval", Value: "-1" },
    { Text: "---------------", Value: "" },
    { Text: "ABPLAT (ABPLAT)", Value: "67" },
    { Text: "AFFIDAVIT (AFF)", Value: "69" },
    { Text: "AGMT/CONT/DEED (A/CDEED)", Value: "68" },
    { Text: "AGREEMENT (AGMT)", Value: "70" },
    { Text: "APPEAL (APPEAL)", Value: "71" },
    { Text: "APPEAL (APPL)", Value: "72" },
    { Text: "ASSIGNMENT (ASSIGN)", Value: "75" },
    { Text: "ASSUMPTION (ASM)", Value: "74" },
    { Text: "BOND (BOND)", Value: "76" },
    { Text: "CANCELLATION (CAN)", Value: "77" },
    { Text: "CANCELLATION NO FEE (CANNF)", Value: "78" },
    { Text: "CC COURT JUDGMENT (CCCJUDG)", Value: "79" },
    { Text: "CERTIFICATE (CERT)", Value: "81" },
    { Text: "CERTIFICATE OF COMPLIANCE (CERT COMP)", Value: "82" },
    { Text: "CERTIFICATE OF TITLE DEED (CERTDEED)", Value: "83" },
    { Text: "CERTIFIED COPY OF COURT ORDER (CCCO)", Value: "80" },
    { Text: "CLERKS CERTIFICATE (CLERKCERT)", Value: "84" },
    { Text: "CONDOMINIUM DECLARATION (DEC/CON)", Value: "86" },
    { Text: "COURT PAPER (CTPAPER)", Value: "85" },
    { Text: "CREDIT UNION MODIFICATION (MODCU)", Value: "109" },
    { Text: "CREDIT UNION MORTGAGE (MTGCU)", Value: "111" },
    { Text: "DEATH CERTIFICATE (DTH/CTF)", Value: "91" },
    { Text: "DEED (DEED)", Value: "87" },
    { Text: "DEED DOC STAMP (DEED DOC)", Value: "88" },
    { Text: "DEPENDENCY NOTICE OF APPEAL (DNOA)", Value: "90" },
    { Text: "DOMESTIC VIOLENCE JUDGMENT (DVJ)", Value: "145" },
    { Text: "DOMESTIC VIOLENCE JUDGMENT (MINOR VICTIM) (DVJMV)", Value: "159" },
    { Text: "EASEMENT (EAS)", Value: "92" },
    { Text: "ELECTRONIC MORTGAGE (EMTG)", Value: "93" },
    { Text: "FINAL JDGMT PUBLIC DEFENDER (FJPD)", Value: "94" },
    { Text: "FINANCE STATEMENT/UCC (FS)", Value: "95" },
    { Text: "GOVERNMENT (EXEMPT FROM ALL TAX) (MTGG)", Value: "112" },
    { Text: "GOVT RELATED (GOV/REL)", Value: "96" },
    { Text: "INACTIVE (IA)", Value: "160" },
    { Text: "JOINDER (JOINDER)", Value: "101" },
    { Text: "JUDGMENT (JDG)", Value: "97" },
    { Text: "JUDGMENT/RESTITUTION (JDGR)", Value: "98" },
    { Text: "JUDGMENT/SENTENCE (JDGS)", Value: "99" },
    { Text: "JUVENILE RESTITUTION LIEN (JVRL)", Value: "102" },
    { Text: "LIEN (LN)", Value: "103" },
    { Text: "LIS PENDENS (LP)", Value: "104" },
    { Text: "MARRIAGE LICENSE (ML)", Value: "105" },
    { Text: "MARRIAGE LICENSE AMENDED (MLA)", Value: "141" },
    { Text: "MARRIAGE LICENSE COUNSELING (MLC)", Value: "106" },
    { Text: "MARRIAGE LICENSE DUPLICATE (MLD)", Value: "142" },
    { Text: "MILITARY DISCHARGE (DISCHG)", Value: "89" },
    { Text: "MODIFICATION (MOD)", Value: "108" },
    { Text: "MORTGAGE (MTG)", Value: "110" },
    { Text: "MORTGAGE MOD (MMOD)", Value: "107" },
    { Text: "MORTGAGE NO DOC STAMP/INTANGIBLE TAX ONLY (MTGND)", Value: "155" },
    { Text: "NO INTANGIBLE MORTGAGE (MTGNI)", Value: "113" },
    { Text: "NON INDEXED IMAGE (NONINDEXED)", Value: "116" },
    { Text: "NOTICE (NOTICE)", Value: "120" },
    { Text: "NOTICE COMMENCEMENT (N/C)", Value: "115" },
    { Text: "NOTICE CONTEST OF LIEN (NOTCONT)", Value: "118" },
    { Text: "NOTICE OF BOND (NOTBOND)", Value: "117" },
    { Text: "NOTICE OF HOMESTEAD (NOTHOMEST)", Value: "119" },
    { Text: "NOTICE OF TAX DEED SALE (NTD)", Value: "149" },
    { Text: "ORDER (ORDER)", Value: "121" },
    { Text: "PARTIAL RELEASE (PTL REL)", Value: "125" },
    { Text: "PLAT (PLAT)", Value: "140" },
    { Text: "PLAT RELATED (PLATREL)", Value: "123" },
    { Text: "POWER OF ATTORNEY (P/A)", Value: "122" },
    { Text: "PROBATE (PROB)", Value: "124" },
    { Text: "RELEASE (RELEASE)", Value: "126" },
    { Text: "REPORT SINKHOLE ACTIVITY (REPORTSA)", Value: "127" },
    { Text: "RESTRICTIONS (RST)", Value: "128" },
    { Text: "RPO FINAL JUDGMENT (RPOFJ)", Value: "146" },
    { Text: "SATISFACTION (SAT)", Value: "129" },
    { Text: "SATISFACTION NO FEE (SATNF)", Value: "130" },
    { Text: "TAX DEED (CITY REDEEMED) (TXDC)", Value: "134" },
    { Text: "TAX DEED (TXD)", Value: "158" },
    { Text: "TAX FREE MORTGAGE (MTGTF)", Value: "114" },
    { Text: "TERMINATION (TERM)", Value: "131" },
    { Text: "TRANS (TRANS)", Value: "133" },
    { Text: "TRANSFER (TRA)", Value: "132" },
    { Text: "VA FINAL JUDGMENT (VAFJ)", Value: "147" },
    { Text: "WARRANT (WRT)", Value: "137" },
    { Text: "WARRANT NO FEE (WRTNF)", Value: "138" },
    { Text: "WITHDRAWAL (WDL)", Value: "135" },
    { Text: "WITHDRAWAL NO FEE (WDLNF)", Value: "136" },
    { Text: "ZZNOT USED (ZZNOT USED)", Value: "139" },
  ];

  return (
    <div className={`popup ${isOpen ? "open" : ""}`}>
      <div className="popup-content popup-content-800">
        <span className="popup-close" onClick={onClose}>
          &times;
        </span>
        <h2>Add New Prompt</h2>

        <form onSubmit={createPromptAPI}>
          <div>
            <select id="prompt_type" name="prompt_type" className="input_field" onChange={handleChange}>
              {listTypes.map((itm) => (
                <option value={itm.Value} disabled={itm.Value === ""} selected={itm.Value == formData.prompt_type}>
                  {itm.Text}
                </option>
              ))}
            </select>
          </div>
          <div>
            <input type="text" id="prompt_name" className="input_field" name="prompt_name" placeholder="Prompt Name" value={formData.prompt_name || ""} onChange={handleChange} />
          </div>
          <div>
            <textarea rows="4" id="prompt_text" className="input_field" name="prompt_text" placeholder="Prompt Text" value={formData.prompt_text || ""} onChange={handleChange} style={{ minHeight: "calc(100vh - 400px)" }} />
          </div>
          {formData.prompt_type && formData.prompt_type < 0 ? (
            <div>
              <input type="text" id="update_field" className="input_field" name="update_field" placeholder="Update field name. (ex. property_quick_flip)" value={formData.update_field || ""} onChange={handleChange} />
            </div>
          ) : (
            <div style={{ textAlign: "left" }}>
              Make sure to include fields to be returned.
              <br />
              property_address=
              <br />
              legal_description=
              <br />
              apn=
              <br />
              owner_name=
            </div>
          )}

          <button type="submit" className={`big_button ${buttonClicked ? "disabledButton" : ""}`} disabled={buttonClicked}>
            {promptInfo == null ? "Create" : "Update"}
          </button>

          <p>{apiResponse}</p>
        </form>
      </div>
    </div>
  );
};

export default Createaiprompt;
